$border: 1px solid #bcbcbd;
$brandcolor: #339379;
$navHeight: 5em;
$inputHeigt: 3.3334em;
$pagePadding: 5.8125em;
$tablePadding: 1.5em;
$paddingAdd: 1em;
$antObject: 1.334em;

@mixin baseFont {
  font-size: 10px !important;
}

@mixin Font15 {
  @media only screen and (max-width: 1280px) {
    font-size: 1.2rem !important;
  }
  font-size: 1.2rem !important;
  @media only screen and (min-width: 1601px) {
    font-size: 1.5rem !important;
  }
}
@mixin FontH2 {
  @media only screen and (max-width: 400px) {
    font-size: 20px !important;
  }
  @media (min-width: 400px) and (max-width: 1280px) {
    font-size: 20px !important;
  }
  font-size: 22px !important;
  @media only screen and (min-width: 1601px) {
    font-size: 26px !important;
  }
}
@mixin FontH4 {
  @media only screen and (max-width: 400px) {
    font-size: 24px !important;
  }
  @media (min-width: 400px) and (max-width: 1280px) {
    font-size: 20px !important;
  }
  font-size: 26px !important;
  @media only screen and (min-width: 1601px) {
    font-size: 30px !important;
  }
}
@mixin FontH3 {
  @media only screen and (max-width: 400px) {
    font-size: 21px !important;
  }
  @media (min-width: 400px) and (max-width: 1280px) {
    font-size: 24px !important;
  }
  font-size: 23px !important;
  @media only screen and (min-width: 1601px) {
    font-size: 25px !important;
  }
}
@mixin Font24P {
  
  @media only screen and (max-width: 400px) {
    font-size: 14px !important;
  }
  @media (min-width: 400px) and (max-width: 1280px) {
    font-size: 15px !important;
  }
  font-size: 15px !important;
  @media only screen and (min-width: 1601px) {
    font-size: 17px !important;
  }
}
@mixin BtnTxt {
  @media only screen and (max-width: 1280px) {
    font-size: 15px !important;
  }
  font-size: 16px !important;
  @media only screen and (min-width: 1601px) {
    font-size: 16px !important;
  }
}
